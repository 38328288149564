<template>
	<div id="app">
		<UnderConstruction />
	</div>
</template>

<script>
// Import the UnderConstruction component
import UnderConstruction from "./components/UnderConstruction.vue";

export default {
	name: "App",
	components: {
		UnderConstruction, // Register the component
	},
};
</script>

<style>
/* Add any global styles here */
</style>

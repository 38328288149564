<template>
	<div class="construction-container">
		<h1>🚧 Under Construction 🚧</h1>
		<p>I am working hard to bring you something amazing. Stay tuned!</p>
		<img
			src="@/assets/construction.png"
			alt="Under Construction"
			class="construction-image"
		/>
		<div class="contact-info">
			<p>
				If you have any questions, feel free to
				<a href="mailto:mail@aashishadhikari.info.np">contact me</a>.
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "UnderConstruction",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap");

body {
	margin: 0;
	padding: 0;
	font-family: "Poppins", sans-serif;
}

.construction-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-width: 500px;
	height: 100vh;
	background: linear-gradient(120deg, #f6d365, #fda085);
	text-align: center;
	color: #333;
}

h1 {
	font-size: 3em;
	margin-bottom: 20px;
	font-family: "Poppins", sans-serif;
}

p {
	font-size: 1.2em;
	margin-bottom: 30px;
	font-family: "Poppins", sans-serif;
}

/* Increase the image size */
.construction-image {
	width: 400px; /* Adjust the width as needed */
	margin-bottom: 20px;
}

.contact-info {
	margin-top: 20px;
	font-size: 1em;
}

.contact-info a {
	color: #0073e6;
	text-decoration: none;
}

.contact-info a:hover {
	text-decoration: underline;
}
</style>
